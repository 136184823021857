<template>
  <swiperSlide v-if="isWorkTimeActive()">
    <swiperSlide class="appointment-card">
      <div
        class="d-flex align-items-center justify-content-center appointment-card-header"
        :class="
          new Date(data).getDate() > new Date().getDate() + 1
            ? 'justify-content-between'
            : 'justify-content-center'
        "
      >
        <template v-if="new Date(data).getDate() === new Date().getDate()">
          <span class="day">{{ $t("veterinary.today") }}</span>
        </template>
        <template
          v-else-if="new Date(data).getDate() === new Date().getDate() + 1"
        >
          <span class="day">{{ $t("veterinary.tomorrow") }}</span>
        </template>
        <template v-else>
          <span class="day ml-2">{{ days[new Date(data).getDay()] }}</span>
          <span class="date"
            >{{ new Date(data).getDate() }}
            {{ months[new Date(data).getMonth()] }}</span
          >
        </template>
      </div>
      <div
        class="appointment-card-body text-center d-flex justify-content-center flex-column"
      >
        <section
          class="d-flex flex-wrap align-baseline w-100 justify-content-around"
          v-for="(period, index) in workTimes[
            new Date(data).getDay() === 6 ? 0 : new Date(data).getDay() + 1
          ].working_periods"
          :key="index"
        >
          <h6 class="d-flex gap_1 justify-content-center align-items-center">
            <span>{{ $t("veterinary.from") }}</span>
            <span class="time">{{ formatTime(period.from) }}</span>
          </h6>
          <h6 class="d-flex gap_1 justify-content-center align-items-center">
            <span>{{ $t("veterinary.to") }}</span>
            <span class="time">{{ formatTime(period.to) }}</span>
          </h6>
        </section>
      </div>
      <div class="appointment-card-footer">
        <b-button variant="primary" class="app-btn p-0" @click="handleBooking">
          {{ $t("veterinary.book") }}
        </b-button>
      </div>
    </swiperSlide>
    <b-modal id="loginModal" v-model="showLoginModal" hide-footer>
      <p>برجاء تسجيل الدخول قبل اختيار موعد الحجز</p>
      <logincomponVue @login-successful="onLoginSuccess" />
    </b-modal>
  </swiperSlide>
</template>

<script>
/*eslint-disable*/
import moment from "moment";
import logincomponVue from "../../../../auth/views/logincompon.vue";

export default {
  components: { logincomponVue },
  props: ["data", "workTimes", "userInfo"],
  data() {
    return {
      months: [
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "مايو",
        "يونيو",
        "يوليه",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
      days: [
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      showLoginModal: false,
    };
  },
  methods: {
    isWorkTimeActive() {
      const dayIndex =
        new Date(this.data).getDay() === 6
          ? 0
          : new Date(this.data).getDay() + 1;
      return this.workTimes[dayIndex] && this.workTimes[dayIndex].is_active;
    },
    handleBooking() {
      if (!localStorage.getItem("userToken")) {
        this.showLoginModal = true;
      } else {
        this.proceedToNextStep();
      }
    },
    onLoginSuccess() {
      this.proceedToNextStep();
      this.modalClosed = true; // Set to true after processing
    },
    proceedToNextStep() {
      const dayIndex =
        new Date(this.data).getDay() === 6
          ? 0
          : new Date(this.data).getDay() + 1;
      const workingPeriods = this.workTimes[dayIndex].working_periods.map(
        (period) => ({
          from: period.from,
          to: period.to,
        })
      );
      this.$emit(
        "chooseAppointment",
        {
          periods: workingPeriods,
          day: new Date(this.data).getDate(),
          month: this.months[new Date(this.data).getMonth()],
          date: moment(new Date(this.data)).format("YYYY-MM-DD"),
          dayEn: this.workTimes[dayIndex].day,
        },
        "appointmentChooseTime"
      );
    },
    formatTime(time) {
      return moment(time, "HH:mm")
        .format("hh:mm A")
        .replace("AM", "صباحًا")
        .replace("PM", "مساءً");
    },
  },
};
</script>

<style scoped>
.swiper-slide {
  width: 370px !important;
}
h6 {
  font-size: 14px;
}
.appointment-card-body {
  height: 100px;
}

.tag-method {
  background-color: var(--iq-warning);
  color: #fff;
  padding: 5px;
  border-radius: 2px;
  transform: rotate(320deg);
  left: 0;
  top: 20px;
}
</style>
